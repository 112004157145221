//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

//import qs from "qs";
import { Toast } from "vant";
export default {
  props: {
    defaultVal: {
      type: Array,
    },
    proLabel: {
      type: String,
      default: "添加抄送",
    },
  },
  name: "Popfield",
  data() {
    return {
      copyArr: [],
      searchVal: "",
      searchHold: "请输入姓名查询",
      valPicker: false,
      pickTitle: "选择抄送人",
      colArr: [],
    };
  },
  created() {
    this.initPop();
  },
  methods: {
    initPop() {
      this.copyArr = this.defaultVal;
    },
    showPicker(reload) {
      if (!reload && this.colArr.length > 0) {
        this.valPicker = true;
        return;
      }
      let ajaxData = {
        url: "/boogoo/getOrgSqlLovData",
        requestData: {
          pathVariableMap: {
            organizationId: "0",
            tenantId: 0,
          },
          headerParamMap: {},
          requestParamMap: {
            enabledFlag: 1,
            lovCode: "HPFM.EMPLOYEE",
            name: this.searchVal, //可选   根据用户输入字段查询
            page: 0,
            size: 10,
          },
          payload: "",
        },
      };
      Toast.loading({
        message: "数据加载中...",
        duration: 0,
      });
      this.axios
        .post(ajaxData.url, ajaxData.requestData)
        .then((res) => {
          console.log("res", res);
          Toast.clear();
          this.valPicker = true;
          if (res && res.data) {
            if (res.data.status == "200") {
              let resultData = JSON.parse(res.data.payload);
              this.buildleaderArr(resultData.content);
            }
          }
        })
        .catch(function () {
          Toast.clear();
          Toast("请求出错了");
        });
    },
    buildleaderArr(data) {
      console.log("buildleaderArr", data);

      this.colArr = [];
      for (var i = 0; i < data.length; i++) {
        this.colArr.push({
          value: data[i].employeeId,
          text: data[i].name,
          obj: data[i],
        });
      }
    },
    pcikConfirm(value) {
      console.log(value);

      this.valPicker = false;
      let isExist = false;
      for (let i = 0; i < this.copyArr.length; i++) {
        if (this.copyArr[i].value == value.value) {
          isExist = true;
          break;
        }
      }
      if (!isExist) {
        this.copyArr.push(value);
      }

      this.$emit("popconfirm", this.copyArr);
    },
    onSearch() {
      this.showPicker(true);
    },
    deleteTag(tagId) {
      console.log(tagId);
      for (let i = 0; i < this.copyArr.length; i++) {
        if (this.copyArr[i].value == tagId) {
          this.copyArr.splice(i, 1);
          break;
        }
      }
    },
  },
  mounted() {},
};
